@charset "utf-8"

@use 'tailwind'

@use '../dato_colors' // override colors from dato

//@import 'mixins/**/*'      // Mixins
@use 'mixins/_font-size'

@use 'base'             // Base

//@import 'utilities/**/*'   // Utilities
@use 'utilities/_wrap'

//@import 'blocks/**/*'      // Blocks
@use 'blocks/_address_list'
@use 'blocks/_address'
@use 'blocks/_auth'
@use 'blocks/_button'
@use 'blocks/_canvas'
@use 'blocks/_card'
@use 'blocks/_cart'
@use 'blocks/_confirm-order'
@use 'blocks/_dialog'
@use 'blocks/_feedback-dialog'
@use 'blocks/_form'
@use 'blocks/_gallery'
@use 'blocks/_hero'
@use 'blocks/_input'
@use 'blocks/_loading'
@use 'blocks/_locale-switcher'
@use 'blocks/_nav'
@use 'blocks/_order_summary'
@use 'blocks/_overlay'
@use 'blocks/_search_bar'
@use 'blocks/_site-nav'
@use 'blocks/_spinner_container'
@use 'blocks/_tab_buttons'

//@import 'custom-blocks/**/*'      // Blocks
@use 'custom-blocks/_account'
@use 'custom-blocks/_cart-section'
@use 'custom-blocks/_category-intro'
@use 'custom-blocks/_category-menu'
@use 'custom-blocks/_cta'
@use 'custom-blocks/_fixed-label'
@use 'custom-blocks/_footer'
@use 'custom-blocks/_home-hero'
@use 'custom-blocks/_insert-email'
@use 'custom-blocks/_market-dialog'
@use 'custom-blocks/_media'
@use 'custom-blocks/_message-form'
@use 'custom-blocks/_overlay-content'
@use 'custom-blocks/_page-hero'
@use 'custom-blocks/_product-box'
@use 'custom-blocks/_product-hero'
@use 'custom-blocks/_product-info'
@use 'custom-blocks/_product-list'
@use 'custom-blocks/_related-product'
@use 'custom-blocks/_slideshow'
@use 'custom-blocks/_table'
@use 'custom-blocks/_toast'

.input-base
    @apply text-black text-sm border outline-none transition duration-500 ease-in-out

.button-base
    @apply text-center text-sm font-semibold transition-bg duration-500 hover:opacity-80 focus:outline-none

.input-base:not(.-small)
    @apply p-3

.input-base.-small
    @apply p-1

.input-base:not(.-error)
    @apply border-gray-300 focus:border-primary focus:ring focus:ring-offset-0 focus:ring-primary-light focus:ring-opacity-50

.input-base.-error
    @apply border-red-400 placeholder-red-400 focus:ring-red-500 focus:border-red-500 focus:ring-opacity-50

:root
    --primary: var(--primary-h) var(--primary-s) var(--primary-l)
    --primary-light: var(--primary-h) var(--primary-s) var(--primary-l) 0.4
    --primary-dark: var(--primary-h) var(--primary-s) calc(var(--primary-l) * 0.5)
    //--contrast-threshold: 75%
    //--switch: calc((var(--primary-l) - var(--contrast-threshold)) * -10000)
    --contrast: 0 0% var(--switch)
    --color-white: #fff
    --color-black: #000
    // --primary-color: #501919
    // --secondary-color: #964343
    --dialog-opacity-background: rgba(0, 0, 0, 0.5)
    --base-header-height: 100px
    --mobile-header-height: 52px
