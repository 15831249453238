header
  height: var(--base-header-height)
  max-width: 1350px
  @apply bg-white flex justify-center items-center w-full px-7 mx-auto
  @media (max-width: 1023px)
    height: var(--mobile-header-height)
    @apply px-2.5

.header__border
  height: 3px
  background-color: var(--primary-color)
  @apply w-full

.nav
  @apply flex w-full items-stretch justify-around

.nav__menu
  order: 1

.nav__logo
  order: 2

.nav__account
  order: 3

.nav__account
  @apply flex items-center justify-end
  @media (max-width: 1023px)
    margin-right: -15px
    .locale-switcher
      display: none

  .change_market
    .change_market--title
      color: var(--primary-color)
      @media (max-width: 480px)
        @apply hidden

    .change_market--country
      @apply font-semibold

    .nav__menu__link
      @apply cursor-pointer

    .nav__menu__link:after
      content: '▾'
      @apply p-1

.nav__menu
  @apply flex justify-around items-center

.nav__logo
  @apply flex justify-center text-2xl
  img
    max-height: var(--base-header-height)
    @apply py-1
    @media (max-width: 1023px)
      max-height: var(--mobile-header-height)

.nav__menu__item
  @apply inline-block text-lg mx-3

.nav__menu__link
  @apply block px-1

.nav__menu,
.nav__account
  flex: 1 1
  &__search_icon
    @apply cursor-pointer px-1.5
    @media (max-width: 1023px)
      display: none

  &__search_icon__left
    @media (min-width: 1024px)
      display: none

  &__search_container
    top: var(--mobile-header-height)

    @media (min-width: 1024px)
      top: var(--base-header-height)
      z-index: 3
      border: 1px solid rgb(38, 49, 65)
      width: 66% !important
      margin: 2% 17%
      @apply absolute p-0 bg-white

    @media (max-width: 1023px)
      z-index: 3
      border-bottom: 1px solid rgb(38, 49, 65)
      border-top: 1px solid rgb(38, 49, 65)
      pad: 45
      top: var(--mobile-header-height)
      @apply absolute p-0 w-full bg-white

  &__search_element
    left: 23%
    z-index: 5
    @apply relative w-1/2

  @media (max-width: 1023px)
    &__search_element
      width: 90%
      left: 5%
      z-index: 5
      @apply relative

.nav_button
  min-width: 50px
  height: 50px
  @apply flex justify-center items-center relative px-2.5

  &:hover
    background: #eee

  &:not(:first-child)
    border-left: 1px solid #ddd

.nav_button__link
  text-decoration: none
  @apply cursor-pointer block

.nav_button__image
  @apply block w-5 h-5
