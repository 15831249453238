.table--bordered, .table--fixed, .table--scrollable, .table--striped
    background-color: #fff
    border: 1px solid #6c757d
    border-collapse: collapse
    color: #000
    margin-bottom: 24px
    table-layout: auto
    width: 100%

.shipping-costs
    font-size: 70%

.table td, .table th, .table---bordered td, .table---bordered th, .table--fixed td, .table--fixed th, .table--scrollable td, .table--scrollable th, .table--striped td, .table--striped th
    line-height: 1.3
    border-top: 1px solid #6c757d
    padding: 12px
    border-right: 1px solid #6c757d
    vertical-align: top
