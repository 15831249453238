@use '../blocks/_hero'
@use '../mixins/_font-size'

.home-hero
  @extend .hero

.home-hero__body
  @extend .hero__body
  @apply text-left

.home-hero__body__inner
  @extend .hero__body__inner
  height: 500px
  z-index: 1
  @apply relative pl-6
  @media (max-width: 1023px)
    @apply p-6

  &::before
    content: ""
    background: linear-gradient(90deg, rgba(0,0,0,.4) 0%, rgba(0,0,0,0) 100%)
    @apply absolute top-0 left-0 bottom-0 right-0 z-0

.home-hero__heading
  @extend .hero__heading
  z-index: 1
  @apply relative text-white
  h1
    @extend .font-big

.home-hero__text
  @extend .hero__text
  z-index: 1
  @apply text-center relative text-white mt-2.5

.home-hero__image
  z-index: -1
  @apply w-full absolute h-full top-0 left-0

  div
    @apply h-full

  img
    @apply w-full h-full object-cover object-center

.text-content
  padding-left: 12vw
  padding-right: 12vw
  @apply bg-gray-200 py-16
  @media (max-width: 1023px)
    padding: 46px 24px
  &__title
    @extend .font-mid-titles
    @media (max-width: 1023px)
      @apply mb-6
  &__text
    @apply text-base
    @media (max-width: 1023px)
      @apply text-sm
    p
      @apply my-5

    .quote
      letter-spacing: -.5px
      @apply text-center text-black font-light text-xl
      @media (max-width: 1023px)
        @apply text-sm
