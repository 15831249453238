.cart
  min-height: 80vh
  @apply flex flex-col justify-center items-center

.cart_nav
  width: 40px
  height: 40px
  @apply flex justify-center items-center relative rounded
  @media (max-width: 1023px)
    width: 30px
    height: 30px
    @apply mr-2.5

.cart__banner
  min-height: 100px
  @apply border-b border-b-gray-100 bg-cover bg-center p-5

.cart__banner__text
  @apply text-white

.cart_number
  background: var(--primary-color)
  border-radius: 50%
  width: 18px
  height: 18px
  z-index: 1
  @apply flex justify-center items-center absolute text-white text-xs font-bold top-0.5 right-0.5

.checkout-page
  @apply text-base

  .wrapper_checkout
    max-width: 1350px
    @apply ml-auto mr-auto px-5

  .section
    @apply mr-5 mb-5

    &__title h1
      @apply text-black mb-3 text-xl font-bold

      &__no-margin
        @apply mb-1

      &__second
        @apply mt-5 mb-1

    &__link
      color: rgba(15, 109, 177, 1)
      @apply mb-2.5 text-xs

    &__detail
      @apply mb-1 text-xs

  .button--submit
    background: var(--primary-color)
    border-color: var(--primary-color)
    @apply text-white text-base p-3.5

  .button--secondary
    @apply text-base

  .address_form__switch
    color: #006298
    @apply text-start cursor-pointer mt-8

  .invoice_checkbox
    @apply mt-5

  .form__field
    min-width: 60%

    @media (max-width: 1023px)
      min-width: 90%

    .form__error
      @apply text-left text-base

    &__tips
      @apply text-left

  .address_list__info
    max-width: 350px
