@use '_button'

.feedback-dialog__error,
.feedback-dialog__success,
.feedback-dialog__product
  @apply flex flex-col items-center

.feedback-dialog__success
  @apply text-green-600 text-2xl
  @media (max-width: 1023px)
    @apply text-sm

.feedback-dialog__error
  color: red
  @apply text-2xl
  @media (max-width: 1023px)
    @apply text-sm

.close-icon
  @apply pt-7

.feedback-dialog__product
  @apply mt-7
  img
    @apply mb-2.5 h-20 w-20

.feedback-dialog__buttons
  @apply grid md:grid-cols-2 gap-3.5

.feedback-dialog__buttons__cart
  @extend .button--primary

.feedback-dialog__buttons__checkout
  // @extend .button--gray TODO

.feedback-dialog__buttons__close
  min-width: 200px
  @apply button button-base bg-gray-200 py-1.5

.feedback-dialog__buttons__cart
  @media (max-width: 768px)
    @apply w-full mb-3

.feedback-dialog__buttons__checkout, .feedback-dialog__buttons__close
  @media (max-width: 768px)
    @apply w-full !ml-0 mb-1

.feedback-dialog__buttons__display
  @apply flex justify-center

  @media (max-width: 1023px)
    @apply flex-wrap

.feedback-dialog__buttons__text
  @apply mb-2.5 text-lg

.feedback-dialog__success span
  @media (max-width: 1023px)
    @apply mt-2.5

.feedback-dialog__error span
  @media (max-width: 1023px)
    @apply mt-2.5
