:root
  --primary-color: #2C2E34
  --color-primary: #2C2E34
  --primary-r: 44
  --primary-g: 46
  --primary-b: 52
  --primary-h: 225
  --primary-s: 8.3%
  --primary-l: 18.8%
  --switch: 100%
:root
  --secondary-color: #BD9860
  --color-secondary: #BD9860
