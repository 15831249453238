@mixin font-cart-checkout
  margin-bottom: 36px
  @apply font-semibold text-2xl
  @media (max-width: 1023px)
    @apply text-base mb-6

main.wrap
  min-height: 50vh

.cart_summary__subtotal
  @apply flex flex-row justify-between py-0.5

.cart_summary__total
  @apply flex flex-row justify-between border-t border-gray-400 mt-7 pt-6 pb-8
  p
    @apply font-semibold text-xl font-normal
  div
    @apply font-semibold text-xl

.cart-container
  @apply container mx-auto max-w-screen-lg px-5 lg:px-0

.cart-body
  @apply flex flex-col md:flex-row md:gap-8 pt-8 items-start

.cart_line
  @apply flex gap-5 pb-8 mb-8 border-b border-b-gray-300

.cart_line-item
  @apply flex-1 flex flex-col min-h-[170px]

.cart_line-item__amount
  @apply flex gap-1 text-sm pt-2
  div
    @apply text-gray-400 font-semibold

.cart_line-item__name
  @apply flex justify-between items-center gap-1
  a
    @apply font-bold

.cart_line-item__quantity
  @apply text-xs bg-gray-100 max-w-max py-1 px-2.5 rounded lowercase text-gray-500 font-bold first-letter:uppercase

.cart_line-item__total-amount
  @apply text-lg font-semibold

.button--update-cart
  @apply button-base bg-primary text-contrast block rounded-md py-2 px-3

.cart_line-item__change-quantity
  @apply relative w-full

.cart_line-item__image
  @apply w-1/4 self-start md:self-center

.coupon-label
  @apply py-1 text-gray-500 text-sm font-bold

.coupon-error
  @apply text-xs text-red-400

.coupon-list
  @apply flex justify-between items-center text-sm mb-2 gap-3

.coupon-remove
  @apply font-bold text-primary border-b leading-none border-black border-opacity-10 transition ease-in duration-200 hover:border-opacity-50 hover:text-primary-dark focus:outline-none

.cart_line-quantity
  @apply flex justify-between items-center mt-auto

.cart_line-end
  @apply flex justify-end

.coupon-input
  @apply flex w-full pt-1 pb-4
  input
    @apply input-base flex-1 rounded-md rounded-tr-none rounded-br-none
  button
    @apply button-base bg-primary text-contrast px-4 rounded-md rounded-tl-none rounded-bl-none

.section__wrap
  @apply w-full md:w-7/12

.cart_summary
  @apply pt-2 pb-8

.cart_summary__delivery
  @apply pb-2 text-center

.cart_summary__spacer
  @apply mb-2

.cart_line-items
  @apply w-full md:w-7/12

.cart-section_wrap
  @apply w-full md:w-5/12 pb-5 md:pb-10 md:px-7 rounded-md

.coupon__wrap
  @apply border-b border-b-gray-400 py-6 mb-6

.checkout, .checkout__header
  max-width: 1024px
  @apply mx-auto

.button-checkout
  @apply button-base bg-primary text-contrast block rounded-md py-3 px-3

.cart_line-item__remove
  @apply cursor-pointer hover:text-red-500

.checkout
  @apply flex
  @media (max-width: 1023px)
    @apply flex-col

.checkout__header
  @apply bg-white mb-6

  &__title
    margin-bottom: 12px !important
    +font-cart-checkout

.checkout__header__message
  @apply flex justify-between
  @media (max-width: 1023px)
    @apply flex-col
  &__text
    flex: 3
    @apply pr-10
  .button--link
    flex: 1
    @apply block my-2.5

  .title--link
    flex: 1
    @apply block my-2.5

.payment-method
  @apply mt-6
  &__title
    color: var(--primary-color)
    @apply text-base font-bold mb-6
  .button--primary
    @apply mt-6

.payment-method__item
  @apply flex
  [type="radio"]
    width: 13px
    height: 22px
    @apply mr-1.5

.payment-method__item__cardicons
  @apply flex

.payment-method__item__cardicons img
  @apply mr-1

.checkout__step__title
  @apply flex justify-between
  +font-cart-checkout
  @apply mb-0 text-base

.checkout__step__greeting--link
  color: var(--secondary-color)
  @apply cursor-pointer font-bold

.checkout__step
  @apply mb-6

.checkout__success
  @apply text-center
  svg
    max-width: 20%

.checkout__error
  svg
    max-width: 30px

.checkout__error, .checkout__error__title, .checkout__error__message
  @apply text-red-500

.form__error
  @apply text-red-500 text-base font-bold

.form__field
  @apply flex flex-col

.checkout__success__title
  @apply text-green-600 text-xl

.checkout__success__message
  @apply my-5
  .checkout__success__message__num
    @apply hidden

.checkout__payment__title
  @apply font-bold

.checkout__payment
  @apply text-left mx-64

  @media (max-width: 1023px)
    @apply text-left mx-4
  li
    list-style-position: inside
    list-style-type: disclosure-closed

.checkout__payment__message
  @apply mb-5 text-sm

.checkout__success__action
  @apply text-white

.button--primary-link
  background: #e4eaf5
  @apply text-gray-600 rounded-sm py-2 px-3

.coupon
  border: 2px dashed #21303b
  letter-spacing: 1px
  @apply uppercase text-center mb-0 rounded-sm p-2.5 bg-white font-bold
