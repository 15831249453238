
.overlay
  @apply block

  &::before
    background-color: var(--dialog-opacity-background)
    content: ""
    z-index: 101!important
    @apply w-full h-full fixed top-0 left-0

  &__container
    z-index: 102
    margin: 15%
    min-width: 70%
    overflow-y: auto
    height: 60%
    @apply absolute top-0 left-0 p-5 bg-white

    @media (max-width: 1023px)
      margin: 5%
      min-width: 90%
