.address
    @apply flex flex-col m-5

    &__wrapper
        @apply flex flex-row items-center

    &__text
        width: 154px
        font-style: Regular
        font-stretch: normal
        text-decoration: none
        inline-size: fit-content
        overflow-wrap: anywhere
        @apply mr-0 mb-0 text-left h-auto font-normal text-xs self-auto

    &__text01
        @apply font-normal

    &__text05
        @apply font-bold

    &__title
        @apply text-xl

    &__subtitle
        @apply text-sm

.button-group-address
    @apply my-4 grid md:grid-cols-2 gap-3.5

.form_address
    @apply grid gap-y-3.5

.form_first_last
    @apply grid md:grid-cols-2 gap-3.5

.form_zip_city_state
    @apply grid md:grid-cols-3 gap-3.5

.section_address
    @apply max-w-screen-sm m-auto
