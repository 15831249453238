.site-nav
  z-index: 100
  @apply flex flex-col relative right-0
  @media (max-width: 1023px)
    @apply m-0
.site-nav__menu
  background: var(--primary-color)
  width: 350px
  transform: translateX(-100%)
  transition: .5s ease
  z-index: 99
  @apply fixed top-0 left-0 bottom-0 py-14 px-6

  &.is-open
    transform: translateX(0)

  .site-nav__account
    @apply flex-col hidden
    .auth__nav
      @apply hidden my-5

  .nav__menu__link
    @apply p-0 text-white

  .locale-switcher__dropdown
    @apply flex
  .locale-switcher__dropdown__link+.locale-switcher__dropdown__link
    border-top: none
  .locale-switcher__dropdown__link:hover
    @apply bg-transparent
  .locale-switcher
    @apply my-2.5
  .locale-switcher__image
    @apply w-auto mr-2.5 h-5
  .locale-switcher:hover .locale-switcher__dropdown
    transform: none
  .locale-switcher__dropdown__link
    @apply p-0
  .cart_nav
    @apply hidden

  .change_market
    @apply my-2.5
    span
      @apply text-white font-semibold
    .nav__menu__link
      @apply px-1

  @media (max-width: 1023px)
    .site-nav__account
      @apply flex

  .change_market
    @apply hidden

    @media (max-width: 1023px)
      @apply block my-2.5
      // padding: 10px 0 0

    .nav_button
      min-width: 0
      @apply p-0 relative inline-block

      &:hover
        @apply bg-transparent

    .nav__menu__link
      @apply p-0

.site-nav__toggle__input__item, .site-nav__toggle__label__item
  width: 25px
  height: 3px
  background: var(--primary-color)
  @apply my-0.5

.site-nav__toggle__label__item
  @apply bg-white

.site-nav__toggle__label__item:first-child
  transform: rotate(45deg) translate(5px, 5px)
.site-nav__toggle__label__item:last-child
  transform: rotate(-45deg) translate(0, 0)

.site-nav__toggle__label
  height: 23px
  width: 23px
  @apply absolute right-5 top-5

.site-nav__toggle__label
  @apply flex flex-col cursor-pointer

.site-nav__menu__item
  @apply my-2.5

.site-nav__menu__link
  @apply uppercase text-white font-bold

  &:hover
    text-decoration: none
    @apply text-white opacity-75

.site-nav__toggle
  -webkit-touch-callout: none
  @apply cursor-pointer

.site-nav__toggle__input
  width: 26px !important
  height: 24px !important
  z-index: 2
  -webkit-touch-callout: none
  @apply cursor-pointer absolute block opacity-0 m-0

.site-nav__toggle__input:checked

  & ~ .site-nav__menu
    transform: translateX(0)

.site-nav__menu__sublist
  @apply ml-2.5
