.auth
  width: 400px
  @apply mx-auto max-w-full
  .form__field
    @apply flex-col relative
    input[type="checkbox"]
      left: -20px
      @apply absolute

.auth__nav
  @apply mx-2.5

.auth__title
  @apply text-center text-xl font-bold mb-6

.form__field.boolean
  @apply flex

  &--row
    @apply flex flex-row items-start mt-2
