.gallery
  @apply my-9

.gallery__title
  @apply text-center mb-5 font-semibold text-2xl
  @media (max-width: 1023px)
    @apply text-xl mb-6

.gallery__list
  grid-gap: 20px
  @apply justify-center grid grid-flow-col
  @media (max-width: 1023px)
    grid-gap: 10px

.gallery__item
  @apply w-full relative

.gallery__item__inner
  @apply w-full max-w-3xl
