.form__field
  @apply mb-6

  &:last-child
    @apply mb-0

.form_label
  @apply block mb-2 text-sm font-medium text-gray-900

.form_input
  @apply border border-gray-400 text-sm rounded-lg focus:ring-1 focus:ring-primary focus:border-primary block w-full px-2.5 py-3 ease-in-out duration-200

.form_error
  @apply text-sm text-red-600

.form_field__tips
  @apply text-sm

.form__label
  @apply block text-black mb-1

.form__input
  border: 1px solid
  transition: border 0.2s cubic-bezier(.55,0,.1,1)
  @apply w-full border-gray-400 bg-gray-50 rounded-sm text-sm px-3 py-1.5

.form__actions
  @apply mt-6

.form__error
  @apply text-gray-200 text-xs mt-3
