.insert_email
    @apply flex flex-row justify-center w-full mx-auto px-5 max-w-screen-xl
    .form__field
        @apply relative

    .insert_email_section_detail_password_icon
        transform: translateY(-50%)
        @apply cursor-pointer absolute top-1/2 right-3

    @media (max-width: 1023px)
        @apply flex-col justify-center items-center

        .insert_email_section_detail
            @apply hidden

        .show
            display: inherit

    &_section
        border-width: 1px
        border-color: rgba(221, 221, 221, 1)
        @apply items-start border-solid rounded-sm m-2.5 pt-2.5 px-2.5 pb-3.5 min-w-80 max-w-80 shrink-0

        &_link
            @apply flex justify-center items-start border-transparent text-base shrink-0

        &_master
            margin-right: 8px
            @apply flex flex-col relative items-start grow border-transparent self-stretch

            &_title
                @apply text-left mr-0 h-auto mb-1.5 pt-1 pb-2.5 text-lg self-stretch

            &_subtitle
                color: #6c757d
                @apply mr-0 text-left h-auto mb-7 self-stretch

                &--margin
                    @apply mb-2.5

            &_icon
                width: 15px
                height: 15px
                top: 5px
                @apply absolute right-0

        &_detail
            &_button
                @apply w-full my-5 text-base

            &_flag
                @apply flex items-start border-transparent text-base shrink-0

                &_label
                    text-decoration: underline
                    @apply cursor-pointer

            &_padding_bottom
                @apply pb-5

            &_input
                width: 16px !important
                height: 16px
                border-width: 1px
                border-color: rgba(221, 221, 221, 1)
                @apply flex relative items-center border-solid rounded-sm mt-1 mr-1.5 p-0.5 shrink-0

            &_label
                @apply text-left mr-0 mb-0 h-auto grow self-auto

.insert__title
    color: var(--primary-color)
    @apply flex flex-col justify-center text-center font-bold pb-4 mb-2.5 mt-5 text-base

    @media (max-width: 1023px)
        @apply text-start px-6
