.textarea
    width: 390px !important
    height: 230px !important
    font: 700
    border: 1px solid
    @apply m-auto text-center border-gray-200 p-0.5

.textarea--no-resizeble
    resize: none

.info__message
    @apply text-center
