.footer
  background-color: #ddd
  @apply pt-7 px-6 pb-3.5
  // height: $base-footer-height

.footer__main
  @apply text-center
  .footer__logo
    @apply flex w-full
    img
      width: 260px
      @apply block h-full
      @media (max-width: 1023px)
        @apply mx-auto

    @media (max-width: 1023px)
      @apply block mx-auto p-6

  @media (min-width: 1024px)
    @apply text-left

.footer__blocks
  @apply flex justify-between mt-10 mb-5
  @media (max-width: 1023px)
    @apply flex-col

.footer__blocks__item
  flex: 1
  gap: 0.7rem
  @apply flex flex-col
  @media (max-width: 1023px)
    @apply mb-3.5
