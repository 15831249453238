.loading

  &.active
    @apply block

  &::before
    background-color: var(--dialog-opacity-background)
    content: ""
    z-index: 1001
    @apply fixed top-0 left-0 h-full w-full

.loading__body
  transform: translate(-50%, -50%)
  background: white
  -webkit-overflow-scrolling: touch
  min-width: 250px
  max-height: 90%
  max-width: 60rem
  z-index: 1002
  @apply fixed top-1/2 left-1/2 flex rounded-2xl items-center flex-col justify-center overflow-y-auto

.loader__message
  margin-top: -1rem
  margin-bottom: 0.5rem
  letter-spacing: 0
  @apply text-center

/** loader **/
.loader
  border-radius: 50%
  width: 10em
  height: 10em
  margin: 60px auto
  text-indent: -9999em
  border-top: 1.1em solid rgba(13, 13, 13, 0.2)
  border-right: 1.1em solid rgba(13, 13, 13, 0.2)
  border-bottom: 1.1em solid rgba(13, 13, 13, 0.2)
  border-left: 1.1em solid #0d0d0d
  -webkit-transform: translateZ(0)
  -ms-transform: translateZ(0)
  transform: translateZ(0)
  -webkit-animation: load8 1.1s infinite linear
  animation: load8 1.1s infinite linear
  @apply relative text-xs

  &:after
    border-radius: 50%
    width: 10em
    height: 10em

@keyframes load8
  0%
    -webkit-transform: rotate(0deg)
    transform: rotate(0deg)

  100%
    -webkit-transform: rotate(360deg)
    transform: rotate(360deg)

.product-box-loading
  @apply animate-pulse
  .product-box__price
    @apply rounded bg-gray-400 h-6 w-14

  .product-box__action
    @apply rounded bg-gray-400 h-10 w-40

.product-loading
  @apply animate-pulse
  .product-hero__price
    @apply rounded bg-gray-400 h-9 w-28

  .product-hero__component
    .product-hero__availability__wrap
      @apply rounded bg-gray-400 h-5 w-60
    .line-2
      @apply rounded bg-gray-400 h-10 w-80
