@use '../mixins/_font-size'

.category-intro
  margin: 36px auto 0
  @apply max-w-screen-xl

.category-intro__title
  @extend .font-mid-titles
  @apply text-center text-black

.category-intro__description
  max-width: 500px
  @apply text-center mx-auto my-3.5
