.address_list
    @apply flex content-center pt-6 pl-3

    @media (max-width: 1023px)
        max-width: 333px
        @apply flex-wrap justify-center m-0

    .addresses_grid
        grid-template-columns: repeat(4, 1fr)
        @apply grid

        @media (max-width: 1400px)
            @apply flex flex-wrap justify-center

    .addresses_grid > :nth-child(3n + 2)
        grid-column-start: 2

    &__new_address
        min-width: 250px
        border: 1px solid rgb(221, 221, 221)
        max-width: 250px
        min-height: 200px
        @apply flex content-center justify-center flex-wrap cursor-pointer items-center flex-nowrap rounded mx-4 mb-5 p-3

        @media (max-width: 1023px)
            height: 100px
            @apply justify-center

        &__label
            @apply uppercase text-left font-normal pl-3 text-xl

    &__item
        border: 1px solid rgb(221, 221, 221)
        min-width: 250px
        width: 250px
        @apply flex flex-col justify-center items-center rounded mx-2.5 mb-4

        @media (max-width: 1023px)
            border-bottom: 1px solid rgb(221, 221, 221)

        &--selected
            height: unset

        &__button_wrapper
            @apply w-full mt-5

        &__button
            border-width: 1px
            min-width: 90%
            @apply flex justify-center cursor-pointer border-solid rounded mb-3 py-1.5 px-4

    &__info
        @apply p-5
