.spinner-container
    width: 60px
    @apply absolute right-0 inline-block

    & div
        margin: 20%
        width: 8px
        height: 8px
        border-radius: 50%
        background: rgb(183, 183, 183, 1)
        animation-timing-function: cubic-bezier(0, 1, 1, 0)
        @apply absolute

    & div:nth-child(1)
        left: 2px
        animation: lds-ellipsis1 0.6s infinite

    & div:nth-child(2)
        left: 2px
        animation: lds-ellipsis2 0.6s infinite

    & div:nth-child(3)
        left: 22px
        animation: lds-ellipsis2 0.6s infinite

    & div:nth-child(4)
        left: 42px
        animation: lds-ellipsis3 0.6s infinite

@keyframes lds-ellipsis1
    0%
        transform: scale(0)
    100%
        transform: scale(1)

@keyframes lds-ellipsis3
    0%
        transform: scale(1)
    100%
        transform: scale(0)

@keyframes lds-ellipsis2
    0%
        transform: translate(0, 0)
    100%
        transform: translate(20px, 0)
