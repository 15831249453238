.font-mid-titles
  margin-bottom: 36px
  @apply text-center font-semibold text-2xl
  @media (max-width: 1023px)
    @apply text-xl mb-6

.font-big
  @apply uppercase text-center font-semibold text-5xl
  @media (max-width: 1023px)
    @apply text-2xl
