@use '../blocks/_dialog'

.market-dialog
  @extend .dialog

.market-dialog__header
  @extend .dialog__header
  @apply relative text-center text-xl

.market-dialog__frame
  @extend .dialog__frame
  width: 400px
  @media (max-width: 1023px)
    width: 90%

.market-dialog__body
  @apply text-center

.market-dialog__footer
  @extend .dialog__footer

.market-dialog__select
  @apply relative inline-block

.market-dialog__select__inner
  border: 1px solid #aaa
  width: 200px
  @apply relative py-1 pr-7 pl-2.5
