.toast
  top: 54px
  right: 10px
  z-index: 20
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)
  @apply flex fixed items-center text-white bg-green-600 rounded py-1.5 px-4

  &--error
    top: 54px
    right: 10px
    z-index: 20
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)
    @apply flex fixed items-center text-white bg-red-500 rounded py-1.5 px-4
