.confirm_order
    @apply flex content-center

    &__grid-container
        grid-template-columns: 1fr 1fr 1fr
        grid-gap: 10px
        @apply grid

        @media (max-width: 1023px)
            grid-template-columns: 1fr
            @apply grid

    &__grid-aside
        grid-column: 3
        grid-row: 1 / 6

        @media (max-width: 1023px)
            grid-column: 1 / 3
            grid-row: 8

    &__grid-content
        grid-column: 1 / 3

    @media (max-width: 1023px)
        max-width: 300px
        @apply flex-wrap justify-center m-auto

    &__close-icon
        @apply flex cursor-pointer justify-between py-6 pr-6

    &__item
        border: 1px solid rgb(221, 221, 221)
        height: 150px
        min-width: 250px
        width: 270px
        @apply flex flex-col justify-center items-center rounded mx-2.5 mb-4

        @media (max-width: 1023px)
            border-bottom: 1px solid rgb(221, 221, 221)
            @apply border-none

        &--selected
            height: unset

        &__button_wrapper
            @apply w-full my-5

            @media (max-width: 1023px)
                @apply w-full mt-5

        &__button
            border-width: 1px
            min-width: 90%
            @apply flex justify-center border-solid rounded mb-3 py-1.5 px-4

    &__info
        border: 1px solid #DDDDDD
        max-width: 350px
        @apply rounded mt-3.5 p-2

        @media (max-width: 1023px)
            &:nth-of-type(1n +1)
                grid-row: 5

            &:nth-of-type(2n + 2)
                grid-row: 2
                grid-column: 1

    &__wrapper_label
        @apply cursor-pointer inline-flex pt-3.5 pb-7

        @media (max-width: 1023px)
            &:nth-of-type(1n + 1)
                grid-row: 6

            &:nth-of-type(2n + 2)
                grid-row: 3
                grid-column: 1

    &__label
        font-style: normal
        @apply text-black text-sm

        &--link
            color: var(--secondary-color)
            @apply cursor-pointer font-bold

.billing-grid, .shipping-grid
    @apply grid

    @media (max-width: 1023px)
        grid-row: 4
        @apply grid

.checkout-page
    @apply text-base

    .section-confirm
        @apply mr-5 mb-5

        &__title h1
            @apply text-black mb-3 text-base font-bold

        &__second
            @apply mt-5 mb-1

            &__no-margin
                @apply mb-1
