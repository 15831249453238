.overlay_content
    @apply flex content-center

    &__button
        @apply flex justify-center mt-3

    &__wrapper_label
        @apply cursor-pointer inline-flex

    &__label
        font-style: normal
        @apply text-black font-bold ml-1.5 text-xs

    &__address__item
        border: 1px solid #DDDDDD
        min-width: 250px
        width: 250px
        @apply flex flex-col justify-center cursor-pointer items-center rounded mx-2.5 mb-4
