.wrapper_account
    max-width: 1420px
    @apply ml-auto mr-auto px-2.5

    .section
        @apply mr-5 mb-5

        &__title h1
            @apply text-black mb-3 text-xl font-bold

    .account
        @apply w-full

        .profile
            @apply p-0

    .profile
        form
            @apply mb-2.5

    .addresses
        @apply mt-6

        @media (max-width: 1023px)
            @apply m-0

    .frame2
        @apply w-full px-3

    .header_section
        @apply flex text-start mr-0 justify-between h-auto mb-2.5 text-base text-black self-auto

        &__profile
            @apply flex text-start mr-0 h-auto mb-5 text-base text-black gap-5 self-auto

            @media (max-width: 1023px)
                gap: 0
                @apply flex-col

        @media (max-width: 1023px)
            @apply text-center ml-0 justify-around p-2.5

        &__text
            width: 154px
            font-style: Regular
            font-stretch: normal
            text-decoration: none
            @apply text-left mr-0 mb-0 h-auto font-normal text-base text-black self-auto

            &--subtitle
                @apply mt-0 font-light text-sm

            &__text04
                @apply font-bold text-2xl

            &__text05
                @apply font-bold

    .form__field
        min-width: 60%

        @media (max-width: 1023px)
            min-width: 90%

        .form__error
            @apply text-left text-base

        &__tips
            @apply text-left

    .user-info-form
        margin-bottom: 3em

    .password-form
        margin-bottom: 3em

    .item_account
        @apply flex flex-wrap justify-center pt-6 pb-3

        .item
            width: 300px
            border: 1px solid rgb(221, 221, 221)
            @apply flex rounded mb-2 mr-7 mb-4 py-3.5

        @media (max-width: 1023px)
            margin: 0 -15px 18px 0

        &__text
            width: 154px
            font-style: Regular
            font-stretch: normal
            text-decoration: none
            @apply text-left mr-0 mb-0 h-auto font-normal text-base text-black self-auto

            &__text01
                @apply uppercase text-xl
        .group2
            height: 28px
            @apply flex items-center justify-between pb-2.5

    .button--primary
        background: var(--primary-color)
        border-color: var(--primary-color)
        @apply text-white text-base

        @media (max-width: 768px)
            @apply w-full

    .button--secondary
        @apply text-base

        @media (max-width: 768px)
            @apply w-full

    .button--link
        color: var(--primary-color)
        @apply flex p-0 items-center m-0 bg-transparent border-transparent font-normal text-sm

    .row-wrapper
        @apply flex gap-5

        @media (max-width: 1023px)
            @apply flex-col

    .tab-buttons
        @apply justify-between
