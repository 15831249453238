.product-info
  @apply flex my-2.5
  // padding: 10px 0
  @media (max-width: 1023px)
    @apply my-2.5 py-1

.product-info__icon
  width: 30px
  height: 30px
  @apply flex mr-2.5
  img
    @apply w-full h-full object-contain

  @media (max-width: 1023px)
    flex: 0 0 20px
    @apply mr-2.5
    img
      max-height: 30px
      @apply w-auto max-w-full

.product-info__content
  flex: 0 0 auto
  max-width: 80%
  @media (max-width: 1023px)
    @apply text-sm

.product-info__title
  letter-spacing: -.5px
  @apply text-base font-bold
  @media (max-width: 1023px)
    @apply text-sm
