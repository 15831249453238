
.product-hero
  @apply flex max-w-screen-xl mx-auto md:py-10 flex-row flex-wrap md:grid md:grid-cols-[40%_60%]
  @media (max-width: 1023px)
    @apply px-6

.product-detail
  @apply bg-gray-50 mb-5 max-w-screen-xl mx-auto py-16 px-28 rounded
  @media (max-width: 1023px)
    @apply px-6 mx-6 py-12

.product-detail__item
  @apply mb-5 mt-2.5

.product-detail__title
  @apply mb-3 text-2xl font-bold
  @media (max-width: 1023px)
    @apply text-xl

.product-hero__action__wrap
  @apply flex flex-wrap items-center
  @media (max-width: 1023px)
    @apply justify-center items-center

.product-hero__description
  @apply py-2

.product-hero__quantity-selector
  border-radius: 5px
  @apply flex items-center float-left bg-gray-50 mr-4
  @media (max-width: 1023px)
    @apply justify-center float-none mr-4 mb-4

  span
    width: 3rem
    @apply text-center px-3.5 text-lg font-medium w-12
  .button
    @apply flex p-0 justify-center items-center text-black bg-gray-50 rounded-none text-xl w-12 h-12
    &:hover
      background: #e3e3e3

  .button:disabled
    @apply opacity-100

.product-hero__image
  min-width: 0
  @apply w-full tracking-normal px-3

.product-hero__image__gallery

  .swiper-button-next,
  .swiper-button-prev
    color: var(--primary-color)

    &:before
      content: ""
      background: rgba(white, 0.8)
      top: -20px
      right: -20px
      bottom: -20px
      left: -20px
      z-index: -1
      @apply block absolute

  .swiper-button-next
    @apply right-5

  .swiper-button-prev
    @apply left-5

.product-hero__image__thumbs
  @apply mt-7
  .swiper-wrapper
    @apply flex flex-wrap

  .swiper-slide img
    transition: opacity .2s material-ease
    @apply cursor-pointer border-2 border-solid border-transparent
    &:hover
      @apply opacity-80

  .swiper-slide-thumb-active img
    @apply border-primary

.product-hero__info-list
  grid-template-columns: 1fr 1fr
  @apply w-full grid mt-12
  @media (max-width: 1023px)
    grid-template-columns: 1fr 1fr
    @apply mt-6

.product-hero__content
  @apply w-full tracking-normal px-3

  @media (max-width: 1023px)
    @apply p-0 mt-6

.product-hero__title
  @apply text-2xl font-semibold sm:text-3xl max-md:text-center

.product-hero__price
  @apply text-2xl font-extrabold sm:text-3xl max-md:text-center

.product-hero__component
  @apply mt-3
  @media (max-width: 1023px)
    @apply text-center

.product-hero__availability__wrap
  flex: 100%
  @apply text-black mb-4
  span.product-hero__availability__label
    @apply hidden
  span.product-hero__availability
    @apply font-semibold

.product-hero__notavailable
  @apply opacity-60

.product-hero__quantity
  @apply font-normal mt-4

.product-hero__action
  @apply max-md:w-full
  button
    letter-spacing: .3px
    @apply h-12 max-h-14 uppercase font-semibold py-3.5 px-7 text-sm
    @media (max-width: 1023px)
      @apply mb-4

  @media (max-width: 1023px)
    .button--primary
      @apply w-full
