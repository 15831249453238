.input
  @apply inline-block
  @apply relative cursor-pointer

  input
    z-index: -1
    @apply absolute opacity-0

.input-spinner
  @apply inline-flex rounded overflow-hidden

.input-spinner-btn-decrement
  @apply button-base bg-primary text-contrast px-3

.input-spinner-element
  @apply border-gray-200 w-12 text-center

.input-spinner-btn-increment
  @apply button-base bg-primary text-contrast px-3
