.button
  @apply cursor-pointer font-normal text-sm px-5 py-2.5 focus:outline-none

  // Share hover and focus styles
  &:hover, &:focus
    text-decoration: none

  &:focus,
  &.focus
    outline: 0

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled
    @apply opacity-65

.button--primary-link, .button--link, .button--small-primary-link
  @apply text-center cursor-pointer inline-block align-middle border-none select-none whitespace-nowrap font-normal py-1.5 px-4 text-sm text-white my-2.5 focus:outline-none

.button--small-primary-link
  @apply p-0 bg-transparent text-gray-600

.button--secondary
  @extend .button
  border: 1px solid var(--primary-color)
  color: var(--primary-color)
  @apply cursor-pointer bg-transparent

.product-box__action .button--primary
  @media (max-width: 1023px)
    @apply py-2

.button--primary
  @extend .button
  @apply button-base font-medium bg-primary text-contrast
