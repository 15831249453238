@use '_nav'

.locale-switcher
  @apply flex
  @media (min-width: 1024px)
    width: 50px
    height: 50px
    @apply relative justify-center items-center

  .site-nav__menu &
    @media (min-width: 1024px)
      @apply hidden

.locale-switcher__link
  @extend .nav_button__link

.locale-switcher__image
  @extend .nav_button__image

.locale-switcher__dropdown
  @media (min-width: 1024px)
    transform: translateX(-50%) translateY(-200%)
    z-index: 3
    box-shadow: 0 0 10px -5px rgba(0,0,0,0.5)
    transition: opacity .5s
    @apply p-0 w-full absolute opacity-0 bg-white left-1/2 top-full

  .locale-switcher:hover &
    transform: translateX(-50%) translateY(0)
    @apply opacity-100

.locale-switcher__dropdown__link
  @extend .locale-switcher__link
  @apply block

  & + &
    border-top: 1px solid #ddd

  &:hover
    background: #eee

.locale-switcher__dropdown__image
  @apply mr-2.5 w-5
  @media (min-width: 1024px)
    width: 50px
    @apply h-5
