.dialog
  @apply hidden

  &.is-active
    @apply block

  &::before
    background-color: var(--dialog-opacity-background)
    content: ""
    z-index: 1001
    @apply w-full h-full fixed top-0 left-0

.dialog__frame
  transform: translate(-50%, -50%)
  -webkit-overflow-scrolling: touch
  box-sizing: border-box
  max-height: 90%
  max-width: 60rem
  overflow-y: auto
  width: 90%
  z-index: 1002
  @apply bg-white fixed top-1/2 left-1/2

.dialog__header
  @apply text-center text-3xl p-6

.dialog__body
  @apply px-6
  .button--link
    text-transform: capitalize
    @apply block cursor-pointer w-auto text-center relative border-none bg-transparent float-none mx-auto my-2.5 opacity-50
    &:hover
      @apply opacity-100

  &__content
    @apply flex flex-col justify-center flex-wrap items-center pt-3 pb-1.5

    &--title
      @apply text-xl

    &--button
      max-width: 400px
      @apply flex justify-center flex-wrap w-full items-center mx-auto mt-2.5
      button
        @apply mx-auto my-2.5

      @media (max-width: 1023px)
        @apply flex-col

      button,
      a
        min-width: 200px
        @apply py-1.5

.dialog__footer
  @apply text-center p-6

.dialog__close
  @apply cursor-pointer p-6 absolute top-0 right-0

.dialog__close__icon
  @apply cursor-pointer w-5

.dialog__body p
  @apply text-center mb-3.5

.dialog__body
  .form__field.boolean
    @apply mb-2.5
    input[type="checkbox"]
      @apply left-0 mt-0.5
    p
      @apply text-left text-xs

  label.form__label.boolean
    @apply mb-0 ml-6

    @media (max-width: 1023px)
      @apply mb-0 ml-10
