@use '../mixins/_font-size'

.media-block
  min-height: 300px
  @apply flex w-full justify-between items-stretch

  @media (max-width: 1023px)
    @apply flex-col

  &:nth-child(even)
    flex-flow: row-reverse

.media-block__left
  @apply p-24

.media-block__left,
.media-block__right
  flex: 0 0 50%
  @media (max-width: 1023px)
    @apply p-6

.media-block__image
  @apply relative h-full

  img
    @apply w-full absolute h-full top-0 left-0 object-cover
    @media (max-width: 1023px)
      @apply relative

.media-block__video
  padding-bottom: 56.5%
  @apply relative

  iframe
    @apply w-full absolute h-full border-none

.media-block__title
  @extend .font-mid-titles
