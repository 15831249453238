.order_summary
  padding: 0 20px
  @apply flex flex-col items-stretch

  &__line-item
    border-bottom: 1px solid #ccd1d6
    @apply flex flex-col items-center mb-5

    &__content
      @apply flex flex-col items-center

  &__title
    @apply flex justify-center text-black mb-2.5 text-base font-bold

  &__num_items
    @apply text-black font-normal m-1 text-sm

  &__detail_row
    border-bottom: 1px solid #ccd1d6
    @apply flex w-full justify-between text-black my-1

    &__title
      @apply text-black text-base font-bold

  &__summary__delivery
    background: #f4e9d7
    color: var(--primary-color)
    @apply uppercase w-full text-center font-normal mb-6 p-2.5
