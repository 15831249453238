.product-box
  @apply text-center relative max-w-full bg-white h-full

.product-box__header
  @media (max-width: 1023px)
    @apply p-0

.product-box__header img
  @media (max-width: 1023px)
    @apply mb-2.5 p-0

.product-box__body
  @apply flex mt-2 p-0 justify-center flex-wrap
  .product-box__fees
    @apply ml-1

  .product-box__fees__label:after
    content: ":"

.product-box__price
  @apply text-base font-semibold

.product-box__action,.product-box__notavailable
  flex: 100%

.product-box__title
  @apply flex justify-center items-center space-y-1 overflow-hidden font-semibold px-3 text-sm md:text-base min-h-12
  @media (max-width: 1023px)
    min-height: 60px

.product-box__content
  @apply p-0

.product-box__footer
  @apply flex justify-center text-center items-stretch p-1.5
  a
    @apply text-black

.product-box__action
  margin: 12px 0

.product-box__notavailable, .product-loading
  @apply opacity-70
  @media (max-width: 1023px)
    @apply text-center

.product-box__price-compare,
.product-hero__price-compare
  @apply hidden

.product-box__image
  img
    @apply m-auto
    @media (max-width: 1023px)
      @apply max-w-full

.product-box__fees__wrap
  @apply items-center ml-2.5 text-xs
  @media (max-width: 1023px)
    @apply ml-1 text-xs
