
.search-bar

  &__wrapper
    @apply w-full

    input[type=text]
      @apply text-xl
      @media (max-width: 1023px)
        @apply text-lg

    input[type=text]::placeholder
      color: #A9A9A9

    &__input
      width: inherit
      left: inherit
      @apply mt-10

  &__group
    @apply flex text-2xl py-2

    &-border
      border-top: 1px solid rgb(38, 49, 65)

    &__title
      @apply uppercase text-lg

  @media (max-width: 1023px)
    &__group
      margin: 0 5%
      @apply mx-0 py-5

  @media (min-width: 480px)
    &__input
      height: 40px
      border: 0
      border-bottom: 1px solid #979797
      @apply w-full align-baseline bg-transparent pl-7 px-3.5 text-black
    &__icon
      @apply cursor-pointer absolute pt-2.5 left-5

    &__close
      left: calc( 100% - 33px)
      @apply block
      rect
        fill: #aaa

  &__input
    height: 40px
    border: 0
    border-bottom: 1px solid #979797
    @apply w-full align-baseline bg-transparent pl-0 px-3.5 text-xl text-black

    &__icon
      @apply cursor-pointer absolute pt-3.5

    &__close
      left: calc( 100% - 33px)
      z-index: 6
      @apply block
      rect
        fill: #aaa

  &__search--results
    position: inherit
    @apply flex justify-center cursor-pointer pt-2.5 text-lg text-black

@media (max-width: 1023px)
  ::-webkit-scrollbar
    width: 3px

  ::-webkit-scrollbar-thumb
    border-radius: 5px
    background-color: #D8D8D8

.search-bar__dropdown
  max-height: 500px
  overflow-y: auto
  @apply w-full bg-white p-5

.search-bar__link
  @apply flex flex-col w-full text-black

  &:hover
    @apply text-black

.search-bar__link__a
  border-bottom: 1px solid #eee
  @apply flex w-full items-center text-lg text-primary

.search-bar__link__a:hover
  span
    @apply text-black

.search-bar__link__img
  height: 75px
  width: 75px
  min-width: 75px
  background-size: contain
  @apply bg-center bg-no-repeat

.search-bar__link__text
  @apply ml-2.5 text-lg transition

.search-bar__link--category
  @apply my-2.5

.search-bar__link--page
  @apply my-2.5
