@use '../mixins/_font-size'

.slideshow
  @apply my-12

  .swiper-wrapper
    height: 530px
    cursor: grab

  .swiper-pagination.swiper-pagination-bullets
    bottom: 40px
    @apply absolute
    @media (max-width: 1023px)
      bottom: 60px

  .swiper-pagination-bullet.swiper-pagination-bullet-active
    background: var(--primary-color)

  .swiper-pagination-bullet
    @apply bg-white opacity-60

    &:hover
      @apply opacity-100

  .swiper-button-next,
  .swiper-button-prev
    width: 50px
    height: 50px
    background: rgba(#fff, .25)
    z-index: 100
    @apply text-white

    &:hover
      @apply opacity-60

  .swiper-button-next
    @apply right-5

  .swiper-button-prev
    @apply left-5

  .swiper-button-next:after, .swiper-button-prev:after
    @apply text-2xl

  .swiper-pagination-bullet.swiper-pagination-bullet-active
    background: var(--primary-color)

  .swiper-pagination-bullet
    @apply bg-white opacity-60

  .swiper-button-next.button-next-slideshow-extravaganza
    @apply text-white right-5

  .swiper-button-prev.button-prev-slideshow-extravaganza
    @apply text-white left-5

  .swiper-button-next:after, .swiper-button-prev:after
    @apply text-2xl

.slideshow-slide__title
  top: 40%
  transform: translateX(-50%) translateY(-50%)
  z-index: 1
  @apply uppercase text-center absolute font-semibold text-5xl text-white left-1/2

  @media (max-width: 599px)
    top: calc(27% + 50px) !important

.slideshow-slide__text
  z-index: 1
  top: 58%
  transform: translateX(-50%) translateY(-50%)
  z-index: 1
  @apply text-center absolute mt-5 text-xs text-white left-1/2

  @media (max-width: 1023px)
    top: calc(40% + 50px) !important
    transform: none
    @apply relative left-0 px-4 text-base

.slideshow-slide__image
  z-index: -1
  @apply w-full absolute h-full top-0 left-0
  div
    @apply h-full

  img
    @apply w-full h-full object-cover object-center

.slideshow__title
  @extend .font-mid-titles
  @apply relative

.slideshow-slide
  &__title
    @extend .font-mid-titles
    @media (max-width: 1023px)
      @apply mb-6
  &__text
    @apply text-2xl
    @media (max-width: 1023px)
      @apply text-base
    p
      @apply my-5

    .quote
      letter-spacing: -.5px
      @apply text-center font-light text-xl text-black
      @media (max-width: 1023px)
        @apply text-sm
