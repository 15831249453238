.hero
  @apply relative bg-cover bg-center bg-no-repeat bg-white

.hero__body
  @apply w-full text-center table

.hero__body__inner
  @apply table-cell align-middle py-12

.hero__heading
  max-width: 80rem
  @apply mr-auto ml-auto text-3xl

.hero__text
  max-width: 75rem
  @apply mr-auto ml-auto mt-6

.hero__actions
  @apply mt-6
