@use '../blocks/_hero'
@use '../mixins/_font-size'

.page-hero
  @extend .hero

.page-hero__body
  @extend .hero__body
  @apply text-left

.page-hero__body__inner
  @extend .hero__body__inner
  height: 400px
  z-index: 1
  @apply relative pl-6

  &::before
    content: ""
    background: rgb(0,0,0)
    background: linear-gradient(90deg, rgba(0,0,0,.4) 0%, rgba(0,0,0,0) 100%)
    @apply absolute top-0 left-0 bottom-0 right-0 z-0

.page-hero__heading
  @extend .hero__heading
  z-index: 1
  @apply relative text-center text-white
  h1
    @extend .font-big

.page-hero__text
  @extend .hero__text
  z-index: 1
  @apply relative text-white mt-2.5

.page-hero__image
  z-index: -1
  @apply w-full absolute  h-full top-0 left-0

  & > div
    @apply h-full

  img
    @apply w-full h-full object-cover object-center
