.category-menu
  background: var(--primary-color)
  height: 68px
  @apply text-center text-white content-center
  @media (max-width: 1023px)
    overflow-x: scroll
    -ms-overflow-style: none
    scrollbar-width: none
    @apply h-auto px-5 p-2.5
    &::-webkit-scrollbar
      @apply hidden

.category-menu__title
  @apply mr-5
  @apply uppercase inline-block
  @media (max-width: 1023px)
    @apply m-0

.category-menu__list
  @apply uppercase items-center inline-flex justify-start

.category-menu__item
  letter-spacing: .3px
  @apply font-semibold px-2.5
  @media (max-width: 1023px)
    min-width: 100px
    @apply mr-2.5 py-2.5
  a
    @apply text-white
    &:hover
      color: var(--secondary-color)
      text-decoration: none
