@use '../mixins/_font-size'

.cta-block
  min-height: 500px
  @apply text-center relative

.cta-block__image
  min-height: 500px
  @apply block relative z-0

  img
    @apply w-full absolute top-0 left-0 h-full object-cover object-center

.cta-block__content
  padding: 0 10vw
  z-index: 1
  @apply flex flex-col justify-center items-center absolute inset-0
  @media (max-width: 1023px)
    padding: 0 2vw

.cta-block__title
  @extend .font-mid-titles

.cta-block__text
  @apply mb-3.5 text-2xl
  @media (max-width: 1023px)
    @apply text-base

.text_block, .title_block
  margin: 36px 0
  @apply px-6
  a.text__link
    text-decoration: underline

.title_block
  @apply text-center

.title_block
  @extend .font-mid-titles

.text_block,
.form__label
  span,
  .text__link
    @apply mr-1

.form__label
  a.text__link
    text-decoration: underline
