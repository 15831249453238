$base-footer-height: 200px !default

body
  @apply text-sm font-light antialiased

*:focus
  outline: 0

input:hover, textarea:hover
  @apply border-gray-400

input:focus, textarea:focus
  @apply border-primary

input[type="checkbox"]
  @apply inline-block mt-1 mr-2.5 w-5 h-5

  @media (max-width: 1023px)
    width: 30px
    height: 30px

main
  min-height: calc( 100vh - var(--base-header-height) - $base-footer-height)
  @apply bg-white

  [class*="icon-svg"]
    height: inherit
    @apply max-w-full

.show_on_mobile
  @apply hidden

  @media (max-width: 1023px)
    display: inherit

.rotateimg180
  @apply rotate-180

li
  @apply list-none
