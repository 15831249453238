@use '../mixins/_font-size'

.related-product
  @apply mt-20
  @media (max-width: 1023px)
    @apply mt-12
  &__title
    @extend .font-mid-titles

.related-product__title
  @apply text-center mb-5 text-2xl font-bold
