.tab-buttons
  @apply flex justify-around my-7

  &__left
    @apply flex

    @media (max-width: 768px)
      @apply flex-col justify-center

      &__back
        @apply flex py-3 text-xl

        &__label
          color: var(--secondary-color)
          @apply flex items-center text-left bg-transparent

          &__icon
            @apply mr-2.5 text-xl

  &__right
    @apply flex p-5

    &__item
      @apply flex items-center cursor-pointer
      > span
        @apply mr-1 text-sm

      &:focus
        outline: none

  &__item
    transition: all ease-in-out .2s
    outline: none
    border-bottom: 2px solid transparent
    @apply cursor-pointer border-none bg-transparent py-2.5 px-10 text-xl text-black

    &:active, &:focus
      outline: none

      &__active
        border-bottom: 4px solid #6C8BB8

    &:disabled
      cursor: default
      color: rgba(0,0,0,.4)
